$theme: ekonomi;
@use "design-system" as ds;

.modalContent {
  @include ds.background(primary, $theme: $theme);
  border-radius: ds.$border-radius--default;
  cursor: auto;
  margin-right: ds.spacing(base);
  margin-left: ds.spacing(base);
  width: 100%;
  max-width: 550px;
}

.modalHeader {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include ds.border(divider--subtle, $b: 1px, $theme: $theme);
  height: ds.spacing(large);
  padding: ds.spacing(base) ds.spacing() ds.spacing(base) ds.spacing();
}

.modalHeaderTitle {
  flex-grow: 1;
  text-align: left;
  @include ds.typography(h3, $theme: $theme);
  @include ds.text-color(primary, $theme: $theme);
}

.modalCloseButton {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  padding: ds.spacing(micro);
  border: none;
  margin-right: ds.spacing(base);
}

.selectButton {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ds.spacing() 0;
  background-color: transparent;
  cursor: pointer;
  @include ds.typography(meta, $theme: $theme);
  @include ds.border(divider--subtle, $b: 1px, $theme: $theme);
  @include ds.text-color(primary, $theme: $theme);

  &:last-child {
    border: 0;
  }

  &:hover {
    @include ds.text-color(brand, $theme: $theme);
  }
}

.checkbox {
  @include ds.colorise-icon(ui, brand, $theme: $theme);
  margin-right: ds.spacing(base);
}

.checkboxLabelNote {
  @include ds.text-color(secondary, $theme: $theme);
}

.modalBody {
  @include ds.pad-lr();
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: right;
  @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
  height: ds.spacing(large);
  padding: ds.spacing() ds.spacing();
}
