$theme: ekonomi;
@use "design-system" as ds;

.container {
  display: flex;
  gap: ds.spacing();
}

.link {
  @include ds.typography(button-text, $theme);
  padding: 0 ds.spacing() 12px ds.spacing();
  border-bottom: 4px solid transparent;
  @include ds.text-color(secondary, $theme: $theme);
  align-self: flex-end;

  @media (min-width: ds.$screen-size--small) {
    padding-bottom: 18px;
  }

  &:hover {
    @include ds.text-color(brand, $theme: $theme);
  }
}

.selected {
  @include ds.border(brand, $b: 4px, $theme: $theme);
  @include ds.text-color(brand, $theme: $theme);

  &:hover {
    @include ds.text-color(primary, $theme: $theme);
  }
}
